export default {
  MAX_ATTACHMENT_SIZE: 5000000, 
    s3: {
     REGION: "us-east-1",
     BUCKET: "deuerlein-attachment-bucket"
    },    
    apiGateway: {
      REGION: "us-east-1",
      URL: "https://710zkr94rb.execute-api.us-east-1.amazonaws.com/prod"
    },
    cognito: {
      REGION: "us-east-1",
      USER_POOL_ID: "us-east-1_eIBQ6RWTy",
      APP_CLIENT_ID: "1bt2lqge171lq4jc0sstb5rfnd",
      IDENTITY_POOL_ID: "us-east-1:88762a6f-6f65-4da9-b864-5d387e0c60ef"
    }
  };